<!--
 * @Author: 月魂
 * @Date: 2021-11-17 11:34:56
 * @LastEditTime: 2022-09-20 18:09:42
 * @LastEditors: 月魂
 * @Description: 演出详情
 * @FilePath: \silk-road\src\views\perform\detail.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      :title="getPerform.title"
      left-arrow
      @click-left="handleBack"
    />
    <div class="video">
      <!-- 此处若为限时展播剧目则展示视频或海报，若为线下演出剧目则展示海报 -->
      <div v-if="getPerform.type === 'online'">
        <video v-if="showVideo" class="video-cont" controls controlslist='nodownload' ref="video" :poster="getPerform.imgUrl">
          <source :src="getPerform.videoUrl">
        </video>
        
        <div v-else>
          <img :src="getPerform.imgUrl" alt="" width="100%">
          <!-- <div class="sum">
            <img class="sum-img" src="../../assets/playSum.png" alt="">
            <div class="sum-text">{{ this.getPerform.sum }} {{ this.$t('params.times') }}</div>
          </div> -->
        </div>
        
      </div>
      <div v-else>
        <img :src="getPerform.imgUrl" alt="" width="100%">
      </div>
      <!-- 此处设计逻辑较为复杂，点击播放按钮时需要去校验当前时间，如果晚于22点，则直接切换为不可播放状态 -->
      <!-- 放置一个视频组件，一个下架提示组件，便于切换 -->
    </div>
    <div class="perform">
      <div class="title">{{ getPerform.title }}</div>
      <div class="ele">
        <div class="team ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Fteam.png" alt="" class="icon"> {{ getPerform.team }}</div>
        <div class="time ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Ftime.png" alt="" class="icon">{{ getPerform.time }}</div>
        <div class="address ele-item"><img src="http://image.wearetogether.com.cn/8th%2Fassets%2Findex%2Fdetail%2Faddress.png" alt="" class="icon"> {{ getPerform.address }}</div>
      </div>
      <div class="intro">
        <div class="intro-cont" v-for="(des, index) in getPerform.des" :key="index">{{ des }}</div>
      </div>
      <!-- 剧团介绍 -->
      <!-- <div class="troupe" v-show="getPerform.teamDes.length">
        <div class="title">{{ this.$t('params.theater') }}</div>
        <div class="cont">
          <div class="text" v-for="(text, index) in getPerform.teamDes" :key="index">{{ text }}</div>
        </div>
      </div> -->
      <!-- 视频精彩剪辑 -->
      <!-- <div class="troupe" v-if="getPerform.clip">
        <div class="title">{{ this.$t('params.clip') }}</div>
        <video :poster="`http://image.wearetogether.com.cn/seven-clip-${getPerform.id}.png`" controls style="display: block; width: 100%">
          <source :src="`http://image.wearetogether.com.cn/seven-clip-${getPerform.id}.mp4`">
        </video>
      </div> -->
      <!-- 主创介绍 -->
      <!-- <div class="troupe" v-show="getPerform.review">
        <div class="title">{{ this.$t('params.review') }}</div>
        <img v-for="(img, index) in getPerform.review" :key="index" :src="getPerform.imgUrl" alt="" class="review" @click="showImg(img)">
      </div> -->
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import dayjs from 'dayjs'
import vue from 'vue';
import { NavBar } from 'vant'
import ImagePreview  from 'vant/lib/image-preview';
import 'vant/lib/image-preview/style';
import { handleBack } from '../../utils/util'

vue.use(ImagePreview).use(NavBar)

export default {
  name: 'PerformDetail',
  components: {
  },
  data() {
    return {
      performId: null,
      now: '',
      imgList: []
    }
  },
  computed: {
    getPerform() {
      let performNode = {};
      Object.values(this.$t('perform.list')).forEach(perform => {
        if (perform.id == this.performId) {
          performNode = perform
        }
      })
      this.wxShareDiy({ title: this.$t('share.title'), desc: `丝艺节云平台: ${performNode.title} - 国家： ${performNode.country}` }, { title: `丝艺节云平台: ${performNode.title} - 国家： ${performNode.country}`, desc: this.$t('share.subTitle') })
      return performNode
    },
    showVideo() {
      const performNode = this.getPerform
      let isShow = false;
      // 限时展播逻辑
      if(performNode.type === 'online') {
        const now = dayjs().format('YYYY-MM-DD');
        const showTime = performNode.showTime
        const startTime = now + ' ' + performNode.limit[0]
        const endTime = now + ' ' + performNode.limit[1]
        // 此处逻辑需要调整，判断showTime中是否含有播出时间，再结合limit判断具体时间段
        if(Object.values(showTime).includes(now) && dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) {
          isShow = true;
          this.$nextTick(() => {
            // 显示展播
            const video = this.$refs.video;
            video.addEventListener('play', () => {
              if(Object.values(showTime).includes(now) && dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) { // 需调整至当天晚十点左右
                isShow = true
              } else {
                video.pause()
                isShow = false;
              }
            })
          })
        } else {
          isShow = false;
        }
      }
      return isShow
    }
  },
  mounted() {
    this.performId = this.$route.params.id;
    // 禁止鼠标右键点击
    document.oncontextmenu = function() {
      return false
    }
    
    // 计算精彩回顾列表
    // if (performNode.review && performNode.review.length) {
    //   performNode.review.map(item => {
    //     this.imgList.push(`${item}`)
    //   })
    // }
    
  },
  methods: {
    showImg(position) {
      if (window.innerWidth <= 1200) {
        ImagePreview({
          images: this.imgList,
          startPosition: position - 1
        });
      }
    },
    handleBack() {
      handleBack()
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  img {
    display: block;
  }
  .video {
    max-width: 1200PX;
    margin: auto;
    position: relative;
    .video-cont {
      display: block;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
    .sum {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, .5);
      padding: 4px 10px;
      display: flex;
      align-items: center;
      color: #fff;
      .sum-img {
        display: block;
        width: 24px;
        height: 100%;
        margin-right: 3px;
      }
      .sum-text {
        line-height: 1;
        font-size: 12px;
      }
    }
    video {
      display: block;
      height: 200px;
      background: #000;
      object-fit: contain;
    }
  }
  .perform {
    width: 100%;
    max-width: 1200PX;
    margin: auto;
    padding: 16px 0;
    font-family: SourceHanSerifCN-Bold;
    color: #4D4D4D;
    .title {
      padding: 0 16px;
      font-size: 21px;
      font-weight: bold;
    }
    .intro {
      width: 100%;
      padding: 16px;
      color: #999;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      line-height: 22px;
      border-radius: 25px 25px 0 0;
      border-top: 1px solid #F5F5F5;
      overflow: hidden;
      .intro-cont {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .ele {
      padding: 0 16px;
      margin-top: 16px;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      font-family: SourceHanSansCN-Regular;
      .ele-item {
        width: 100%;
        display: flex;
        margin-top: 4px;
        &:first-child {
          margin-top: 0;
        }
        .icon {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
    // 剧团介绍
    .troupe {
      .title {
        margin: 10px 0;
      }
      .review {
        display: block;
        width: 100%;
        margin-top: 8px;
      }
      .cont {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        // background-image: linear-gradient(to right, #f39b03, #984081);
        color: #3a3a3a;
        background-color: #e9e9e9;
        .text {
          width: 100%;
          font-size: 14px;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>
